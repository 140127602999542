@mixin LargeButton {
  height: var(--36px);
  @include XLMedium;
}

@mixin XSButton {
  padding: var(--4px) var(--8px);
  @include XSRegular;
}

@mixin TypeTwoButton {
  height: var(--56px);
  // padding: var(--3px) var(--70px);
  @include XL2Medium;
}

.btn {
  padding: var(--8px) var(--12px);
  border-radius: var(--4px);
  @include MMedium;
  position: relative;
  width: fit-content;

  &:disabled {
    background-color: var(--surface-08) !important;
    color: var(--text-tertiary) !important;
    cursor: not-allowed;
  }

  &.full-w {
    width: 100%;
  }

  &.large {
    @include LargeButton;
  }

  &.rounded {
    border-radius: 9999rem;
  }

  &.primary {
    background-color: var(--primary);
    color: var(--text-negative);

    p {
      color: var(--text-negative);
    }

    &:hover {
      background: linear-gradient(0deg, rgba(0, 0, 0, 0.1) 0%, rgba(0, 0, 0, 0.1) 100%),
        var(--primary);
    }
    &.type-a {
      background-color: var(--surface-primary);
      color: var(--text-negative);

      &:hover {
        background-color: var(--surface-primary-hover);
      }
    }

    &.type-grey {
      background-color: var(--surface-08);
      color: var(--text-tertiary);

      &:hover {
        background-color: var(--surface-05);
      }
    }

    &.type-2 {
      @include TypeTwoButton;
    }
  }

  &.primary-dark {
    background: var(--primary-20);
    color: var(--primary);
    @include LMedium;

    p {
      @include LMedium;
      color: var(--primary);
    }

    &.type-2 {
      @include TypeTwoButton;
    }

    &:hover {
      background: var(--primary-gradient), var(--primary-20);
    }
  }

  &.secondary {
    background: var(--surface-05);
    color: var(--primary);

    p {
      color: var(--primary);
      @include MMedium;
    }

    &.with-chev {
      padding-right: var(--8px);
    }

    &:hover {
      background: var(--secondary-gradient), var(--surface-05);
    }
  }

  &.gap {
    display: flex;
    align-items: center;
    gap: var(--8px);
  }

  &.circle {
    border-radius: 100px;
    border: 1px solid var(--surface-primary);
    height: var(--32px);
    width: var(--32px);
    background-color: transparent;
    display: flex;
    align-items: center;
    justify-content: center;

    &:hover {
      background-color: var(--surface-primary) !important;
    }

    &.hovered {
      background-color: var(--secondary);
      color: var(--text-negative);
    }
  }

  &.small-grey {
    @include XSButton;
    background-color: var(--surface-10);
  }

  &.transparent {
    background-color: transparent;

    &.border {
      border: 1px solid var(--primary);
    }
  }

  &.claim {
    @include LMedium;
    background-color: var(--pill-orange);
    color: var(--white);
    padding: var(--4px) var(--12px);
  }
}

.mr-16 {
  margin-right: var(--16px);
}
