.earn {
  height: calc(100vh - var(--72px));
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  @include JumboBold;

  .inner-earn {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    max-width: var(--526px);
    gap: var(--36px);

    .earn-title {
      margin-bottom: var(--12px);
      text-align: center;
    }
  }
}
