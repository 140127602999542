.wallet-modal {
  max-height: var(--636px);
  width: var(--400px);
  padding: var(--16px);
  background-color: var(--wallet-modal-bg);
  border-radius: var(--16px);
  display: flex;
  flex-direction: column;
  position: relative;

  @include mobile-style {
    width: 100%;
    display: flex;
  }

  .back {
    position: absolute;
    top: var(--0px);
    left: var(--0px);
    padding: var(--14px) var(--12px);
  }

  h3 {
    @include XLMedium;
    text-align: center;
  }

  .wallets-list {
    display: flex;
    height: 100%;
    width: 100%;
    margin-top: var(--32px);
    flex-direction: column;
    gap: var(--24px);
    background-color: var(--white);
    border-radius: var(--16px);
    padding: var(--8px);

    .wallet-container {
      display: flex;
      align-items: center;
      width: 100%;
      justify-content: space-between;
      gap: var(--16px);
      padding: var(--12px) var(--16px);
      cursor: pointer;
      border-radius: var(--12px);

      @include mobile-style {
        width: 45%;
        padding: var(--12px) 0;
      }

      &.disabled {
        @include mobile-style {
          cursor: initial;
          opacity: 1;
        }
        cursor: not-allowed;
        opacity: 0.5;
        &:hover {
          border: 1px solid var(--surface-05);
        }
      }

      .wallet-icon {
        height: var(--48px);
        width: var(--48px);
      }

      .wallet-info {
        text-align: right;

        h3 {
          @include LMedium;
          color: var(--text-primary);
          text-align: right;
        }

        h4 {
          text-align: right;
          @include XSMedium;
          color: var(--text-tertiary);
        }
      }

      &:hover {
        background-color: var(--surface-05);
      }

      &.connected {
        width: 100%;
        height: var(--144px);
        display: flex;
        align-items: center;
        flex-direction: row;
        // margin-top: var(--32px);

        border: 1px solid var(--primary);
        background-color: var(--primary-20);
        cursor: default;

        &:hover {
          border: 1px solid var(--surface-05);
          cursor: default;
        }

        .connect-icon {
          height: var(--32px);
          width: var(--32px);
          // margin-right: var(--24px);
          margin-left: auto;
          transform: translateX(-24px);
        }

        .connected-icon {
          height: var(--64px);
          width: var(--64px);
        }

        .lined {
          display: flex;
          flex-direction: row;
          align-items: center;
          justify-content: center;
          gap: var(--16px);
          width: 100%;
        }
      }
    }
  }
}
