.explore {
  padding: var(--0px) var(--40px);

  @include mobile-style {
    padding: 0;
  }
  width: 100%;
  display: flex;
  flex-direction: column;
  flex-grow: 1;

  .explore-header {
    display: flex;
    align-items: center;
    margin-top: var(--48px);
    gap: var(--16px);

    .sort-by-container {
      display: flex;
      align-items: center;
      gap: var(--12px);

      .sort-by {
        color: var(--text-secondary);
        @include MRegular;
      }
    }
  }
}

.vaults-container {
  display: flex;
  flex-direction: column;
  gap: var(--16px);
  margin-top: var(--16px);

  .scroll-content {
    display: flex;
    flex-direction: column;
    gap: var(--8px);
  }

  &.minimal {
    margin-top: 0;
  }
}

.vaults-container-scrolling {
  display: flex;
  flex-direction: column;
  margin-top: var(--16px);
  height: 100%; // Set a fixed height for the container, adjust as needed
  overflow: hidden;
  position: relative;

  &.minimal {
    margin-top: 0;
  }

  .scroll-content {
    display: flex;
    flex-direction: column;
    gap: var(--16px);
    animation: scroll 20s linear infinite;
  }

  &:hover .scroll-content {
    animation-play-state: paused;
  }
}

@keyframes scroll {
  0% {
    transform: translateY(0);
  }
  100% {
    transform: translateY(-50%);
  }
}

// Pause animation on hover
.vaults-container-scrolling:hover::before,
.vaults-container-scrolling:hover::after {
  animation-play-state: paused;
}

.vault-row {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 var(--24px) var(--0px) var(--16px);
  height: var(--100px);
  border-radius: var(--24px);
  border: 1px solid var(--surface-20);
  box-shadow: 0px 4px 6px 0px rgba(0, 0, 0, 0.05);
  width: 100%;
  background-color: var(--text-negative);

  &.show-row {
    display: flex;
  }

  &.hide-row {
    display: none;
  }

  &.flat {
    background-color: transparent;
    border: none;
    box-shadow: none;
    padding-left: 0;
  }

  &.hovered {
    border: 1px solid transparent;
    background-color: var(--text-negative);
    cursor: pointer;
    border: 1px solid var(--primary);
    box-shadow: none;
  }

  &.minimal {
    .left {
      width: 100%;
    }
  }

  .left {
    display: flex;
    align-items: center;
    width: 57%;

    .vault-meta {
      min-width: 50%;
    }

    .data-points {
      display: flex;
      align-items: center;
      gap: var(--32px);

      .divider {
        height: var(--48px);
        background-color: var(--black-alpha-10);
        width: 1px;
      }
    }
  }

  .right {
    display: flex;
    align-items: center;
    gap: var(--16px);
    // flex-grow: 1;
    width: 36%;
    // justify-content: space-between;
    justify-content: flex-end;

    .action-btns {
      display: flex;
      align-items: center;
      gap: var(--16px);
    }
  }
}

.vault-row-skeleton {
  align-items: center;
  justify-content: space-between;
  padding: 0 var(--24px) var(--0px) var(--16px);
  height: var(--100px);
  border-radius: var(--24px);
  border: 1px solid var(--surface-20);
  box-shadow: 0px 4px 6px 0px rgba(0, 0, 0, 0.05);
  width: 100%;
  background-color: var(--text-negative);
  display: flex;

  &.minimal {
    .left {
      width: 100%;
    }
  }

  .left {
    display: flex;
    align-items: center;
    width: 57%;

    .vault-meta {
      min-width: 50%;
      display: flex;
      align-items: center;
      gap: var(--8px);

      .skeleton-circle {
        height: 40px;
        width: 40px;
        background-color: var(--surface-10);
        border-radius: 9999rem;
        animation: skeleton-loading 1.5s infinite ease-in-out;
      }

      .skeleton-text {
        height: 20px;
        width: 240px;
        background-color: var(--surface-10);
        border-radius: 4px;
        animation: skeleton-loading 1.5s infinite ease-in-out;
      }
    }

    .skel-data-con {
      display: flex;
      align-items: center;
      gap: var(--16px);
      .skeleton-data {
        height: 24px;
        width: 80px;
        background-color: var(--surface-10);
        border-radius: 4px;
        animation: skeleton-loading 1.5s infinite ease-in-out;
      }

      .divider {
        height: var(--48px);
        background-color: var(--black-alpha-10);
        width: 1px;
      }
    }
  }

  .right {
    display: flex;
    align-items: center;
    gap: var(--16px);
    width: 36%;
    justify-content: flex-end;

    .action-btns {
      display: flex;
      align-items: center;
      gap: var(--16px);

      .skeleton-text {
        height: 20px;
        width: 240px;
        background-color: var(--surface-10);
        border-radius: 4px;
        animation: skeleton-loading 1.5s infinite ease-in-out;
      }

      .skeleton-button {
        height: 40px;
        width: 100px;
        background-color: var(--surface-10);
        border-radius: 20px;
        animation: skeleton-loading 1.5s infinite ease-in-out;
      }
    }
  }
}

@keyframes skeleton-loading {
  0%,
  100% {
    background-color: var(--surface-10);
  }
  50% {
    background-color: var(--surface-20);
  }
}

.vault-details {
  display: flex;
  flex-direction: column;
  gap: var(--24px);
  padding-bottom: var(--80px);
  width: 100vw;
  background-color: var(--text-negative);
  position: fixed;
  top: 0;
  left: 0;

  .top-details {
    display: flex;
    align-items: center;
    gap: var(--16px);
    width: 100%;
    height: var(--72px);

    .back-chev {
      cursor: pointer;
      padding: var(--16px);
    }
  }

  .bottom-details {
    display: flex;
    gap: var(--110px);
    margin-top: var(--90px);
    padding: 0 var(--40px);

    .left-details {
      flex-grow: 1;
      display: flex;
      flex-direction: column;
      gap: var(--32px);
      height: calc(100vh - 100px);
      overflow-y: auto;
      @include hide-scrollbar;
    }

    .right-details {
      width: var(--464px);
      display: flex;
      flex-direction: column;
      gap: var(--24px);

      .right-details-content {
        display: flex;
        flex-direction: column;
        gap: var(--20px);

        .label {
          @include XL2Bold;
          color: var(--text-primary);
        }
      }
    }
  }
}

@keyframes skeleton-loading {
  0% {
    background-color: var(--surface-10);
  }
  50% {
    background-color: var(--surface-20);
  }
  100% {
    background-color: var(--surface-10);
  }
}

.strategy-info {
  display: flex;
  flex-direction: column;
  gap: var(--32px);

  h3 {
    @include XL2Medium;

    color: var(--text-primary);
  }

  p {
    @include LRegular;
    color: var(--text-secondary);
  }
}

.apr-hover {
  position: absolute;
  width: var(--320px);
  background-color: var(--text-negative);
  padding: var(--24px);
  border-radius: var(--24px);
  border: 1px solid var(--surface-20);
  box-shadow: 0px 2px 0px 0px rgba(0, 0, 0, 0.1), 0px 5px 6px 0px rgba(0, 0, 0, 0.1);
  z-index: 1;
  transform: translate(-25%, -60%);

  display: flex;
  gap: var(--16px);
  flex-direction: column;
}
