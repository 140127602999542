[data-theme='light'] {
  --white: #ffffff;
  --surface-primary: #0e0f0c;
  --surface-primary-hover: rgba(14, 15, 12, 0.8);
  --canopy-bg: #4fbb87;

  --text-negative: #ffffff;
  --background: #ffffff;
  --text-primary: #0e0f0c;
  --text-secondary: rgba(64, 77, 71, 0.8);
  --text-tertiary: rgba(64, 77, 71, 0.5);

  --primary: #4fbb87;
  --primary-10: rgba(79, 187, 135, 0.1);
  --primary-20: rgba(79, 187, 135, 0.2);
  --primary-gradient: linear-gradient(
    0deg,
    rgba(79, 187, 135, 0.1) 0%,
    rgba(79, 187, 135, 0.1) 100%
  );
  --primary-light: #00ffe0;
  --primary-extra-light: #c9f0dc;

  --secondary: #404d47;
  --secondary-gradient: linear-gradient(
    0deg,
    rgba(64, 77, 71, 0.05) 0%,
    rgba(64, 77, 71, 0.05) 100%
  );
  --surface-05: rgba(64, 77, 71, 0.05);
  --surface-08: rgba(64, 77, 71, 0.08);
  --surface-10: rgba(64, 77, 71, 0.1);
  --surface-20: rgba(64, 77, 71, 0.2);
  --surface-50: rgba(64, 77, 71, 0.5);
  --surface-60: rgba(64, 77, 71, 0.6);
  --surface-helper: rgba(255, 187, 56, 0.2);

  --gradient: linear-gradient(90deg, --primary 0%, --primary-light 100%);
  --border-image: var(--border-gradient) 1;
  --border-color: rgba(64, 77, 71, 0.2);
  --border: 1px solid var(--border-color);

  --black-alpha-05: rgba(0, 0, 0, 0.05);
  --black-alpha-10: rgba(0, 0, 0, 0.1);
  --black-alpha-15: rgba(0, 0, 0, 0.15);
  --black-alpha-20: rgba(0, 0, 0, 0.2);
  --black-alpha-50: rgba(0, 0, 0, 0.5);
  --black-alpha-85: rgba(0, 0, 0, 0.85);

  --pill-yellow: #ffbb37;
  --pill-pink: #fb38ff;
  --pill-cyan: #00d3b9;
  --pill-grey: rgba(112, 112, 112, 0.8);
  --pill-blue: #109bff;
  --pill-orange: #ff9900;
  --pill-purple: #ad00ff;

  --success-green: #00b25d;

  --rewards-gradient: linear-gradient(90deg, var(--pill-orange) 0%, var(--pill-purple) 100%);
  --wallet-modal-bg: #f5f6f6;
}

[data-theme='dark'] {
  --white: #ffffff;
  --surface-primary: #0e0f0c;
  --surface-primary-hover: rgba(14, 15, 12, 0.8);

  --text-negative: #000;
  --background: #000;
  --text-primary: #0e0f0c;
  --text-secondary: rgba(64, 77, 71, 0.8);
  --text-tertiary: rgba(64, 77, 71, 0.5);

  --primary: #4fbb87;
  --primary-10: rgba(79, 187, 135, 0.1);
  --primary-20: rgba(79, 187, 135, 0.2);
  --primary-gradient: linear-gradient(
    0deg,
    rgba(79, 187, 135, 0.1) 0%,
    rgba(79, 187, 135, 0.1) 100%
  );
  --primary-light: #00ffe0;
  --primary-extra-light: #c9f0dc;

  --secondary: #404d47;
  --secondary-gradient: linear-gradient(
    0deg,
    rgba(64, 77, 71, 0.05) 0%,
    rgba(64, 77, 71, 0.05) 100%
  );
  --surface-05: rgba(64, 77, 71, 0.05);
  --surface-08: rgba(64, 77, 71, 0.08);
  --surface-10: rgba(64, 77, 71, 0.1);
  --surface-20: rgba(64, 77, 71, 0.2);
  --surface-50: rgba(64, 77, 71, 0.5);
  --surface-60: rgba(64, 77, 71, 0.6);

  --gradient: linear-gradient(90deg, --primary 0%, --primary-light 100%);
  --border-image: var(--border-gradient) 1;
  --border-color: rgba(64, 77, 71, 0.2);
  --border: 1px solid var(--border-color);

  --black-alpha-05: rgba(0, 0, 0, 0.05);
  --black-alpha-10: rgba(0, 0, 0, 0.1);
  --black-alpha-15: rgba(0, 0, 0, 0.15);
  --black-alpha-20: rgba(0, 0, 0, 0.2);
  --black-alpha-50: rgba(0, 0, 0, 0.5);
  --black-alpha-85: rgba(0, 0, 0, 0.85);

  --pill-yellow: #ffbb37;
  --pill-pink: #fb38ff;
  --pill-cyan: #38ffff;
  --pill-grey: rgba(112, 112, 112, 0.8);
  --pill-blue: #109bff;
  --pill-orange: #ff9900;
  --pill-purple: #ad00ff;

  --rewards-gradient: linear-gradient(90deg, var(--pill-orange) 0%, var(--pill-purple) 100%);
  --wallet-modal-bg: #f5f6f6;
}
