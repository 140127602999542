@mixin XL6Bold {
  font-weight: 600;
  font-size: var(--54px);
  line-height: var(--48px);
}
@mixin JumboBold {
  font-weight: 600;
  font-size: var(--46px);
}
@mixin Jumbo {
  font-weight: 400;
  font-size: var(--44px);
  letter-spacing: 0.44px;
}
@mixin XL3Medium {
  font-weight: 500;
  font-size: var(--30px);
  line-height: var(--40px);
}
@mixin XL3Regular {
  font-weight: 400;
  font-size: var(--30px);
  line-height: var(--40px);
}
@mixin XL22Medium {
  font-weight: 500;
  font-size: var(--22px);
}
@mixin XL2Medium {
  font-size: var(--20px);
  font-weight: 500;
}
@mixin XL2Bold {
  font-weight: 600;
  font-size: var(--20px);
}
@mixin XLBold {
  font-weight: 600;
  font-size: var(--18px);
}
@mixin XLMedium {
  font-weight: 500;
  font-size: var(--18px);
}
@mixin XLRegular {
  font-weight: 400;
  font-size: var(--18px);
}
@mixin LRegular {
  font-weight: 400;
  font-size: var(--16px);
}
@mixin LMedium {
  font-weight: 500;
  font-size: var(--16px);
}
@mixin LBold {
  font-weight: 600;
  font-size: var(--16px);
}
@mixin MRegular {
  font-weight: 400;
  font-size: var(--14px);
}
@mixin MMedium {
  font-weight: 500;
  font-size: var(--14px);
}
@mixin SMedium {
  font-weight: 500;
  font-size: var(--13px);
}
@mixin SRegular {
  font-weight: 400;
  font-size: var(--13px);
}
@mixin XSRegular {
  font-weight: 400;
  font-size: var(--12px);
}
@mixin XSMedium {
  font-weight: 500;
  font-size: var(--12px);
}
@mixin XSBold {
  font-weight: 600;
  font-size: var(--12px);
}

@mixin theme-aware($light-value, $dark-value) {
  [data-theme='light'] & {
    @content ($light-value);
  }
  [data-theme='dark'] & {
    @content ($dark-value);
  }
}

// .my-component {
//   @include theme-aware {
//     background: if(&, black, white);
//     color: if(&, white, black);
//   }
// }
