body {
  margin: 0;
  font-family: 'Inter', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: var(--background);
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

@mixin resetStyles() {
  * {
    font-weight: inherit;
    font-family: inherit;
    font-style: inherit;
    font-size: 100%;
    border: 0 none;
    outline: 0;
    padding: 0;
    margin: 0;
    box-sizing: border-box;
    color: var(--text-primary);
  }
}
@include resetStyles();

button,
a {
  cursor: pointer;
}

button {
  &:disabled {
    cursor: default;
  }
}

a {
  color: inherit;
  text-decoration: none;
}

.is-flex {
  display: flex;
  align-items: center;

  &.spread {
    width: 100%;
    justify-content: space-between;
  }
}

.clickable {
  cursor: pointer;
}

.center-text {
  text-align: center;
}

.hr {
  height: 1px;
  width: 100%;
  background-color: var(--surface-20);
}

.hr-global-margin {
  height: 1px;
  width: 100%;
  background-color: var(--surface-20);
  margin-top: var(--16px);
  margin-bottom: var(--16px);
}

@mixin mobile-style {
  @media (max-width: 740px) {
    @content;
  }
}

.mobile-only {
  display: none !important;
  @include mobile-style {
    display: flex !important;
  }
}

.go2072408551 {
  max-width: fit-content !important;
}

.toast-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: var(--black-alpha-85);

  padding-right: 1rem;
  overflow-wrap: break-word;
  a {
    text-decoration: underline;
  }
}

.toast {
  div {
    width: 18rem;
    background-color: var(--black-alpha-85);
  }
}

@mixin hide-scrollbar {
  // For Webkit-based browsers
  &::-webkit-scrollbar {
    display: none;
  }

  // For Firefox
  scrollbar-width: none;

  // Optional: For IE and Edge
  -ms-overflow-style: none;
}

.horizontal-bar {
  width: 100%;
  height: var(--32px);
  background: repeating-linear-gradient(
    135deg,
    // Diagonal lines
    var(--surface-08),
    // Light grey color for the lines
    var(--surface-08) 5px,
    transparent 5px,
    transparent 10px
  );
}
.recharts-text.recharts-cartesian-axis-tick-value {
  fill: var(--text-tertiary) !important;

  tspan {
    fill: var(--text-tertiary) !important;
  }
}

.nowrap {
  white-space: nowrap;
  text-wrap: nowrap;
}
