.modal {
  position: fixed;
  top: 0;
  left: 0;
  opacity: 0;
  visibility: hidden;
  transition: visibility 10ms ease;
  transition: opacity 200ms ease-in;
  overflow: auto;
  z-index: 9997;

  &.high-z-index {
    z-index: 10000;
  }

  @include mobile-style {
    display: none !important;
  }

  &.is-active {
    display: flex;
    visibility: visible;
    opacity: 1;
  }

  .modal-background {
    height: 100vh;
    width: 100vw;
    background-color: var(--black-alpha-50);
    z-index: 98;
  }

  .modal-content {
    position: absolute;
    top: 50%;
    left: 50%;
    max-height: 100vh;
    transform: translate(-50%, -50%);
    z-index: 9999;

    &::-webkit-scrollbar {
      display: none;
    }

    &.top-down {
      transform: translate(-50%, -175%);

      &.live {
        transform: translate(-50%, -50%);
      }

      transition: transform 300ms cubic-bezier(0.17, 0.04, 0.03, 0.94);
    }

    &.bot-up {
      transform: translate(-50%, 175%);

      &.live {
        transform: translate(-50%, -50%);
      }
      transition: transform 300ms cubic-bezier(0.17, 0.04, 0.03, 0.94);
    }

    &.left-in {
      transform: translate(-175%, -50%);

      &.live {
        transform: translate(-50%, -50%);
      }
      transition: transform 300ms cubic-bezier(0.17, 0.04, 0.03, 0.94);
    }
  }
}

.mobile-modal {
  position: fixed;
  top: 0;
  left: 0;
  opacity: 0;
  visibility: hidden;
  transition: visibility 10ms ease;
  transition: opacity 200ms ease-in;
  overflow: auto;
  z-index: 97;
  display: none;

  @include mobile-style {
    &.is-active {
      display: flex !important;
      visibility: visible;
      opacity: 1;
    }
  }

  .modal-background-mobile {
    height: 100vh;
    width: 100vw;
    background-color: var(--black-alpha-50);
    z-index: 98;
  }

  .modal-content-mobile {
    position: absolute;
    bottom: 0;
    left: 0;
    height: fit-content;
    width: 96vw;
    background-color: var(--text-negative);
    // transform: translate(-50%, -50%);
    z-index: 9999;
    display: flex;
    margin: var(--8px);
    display: flex;

    &::-webkit-scrollbar {
      display: none;
    }

    &.top-down {
      // transform: translate(-50%, -175%);

      &.live {
        // transform: translate(0%, -50%);
      }

      // transition: transform 300ms cubic-bezier(0.17, 0.04, 0.03, 0.94);
    }

    &.bot-up {
      // transform: translate(-50%, 175%);

      &.live {
        // transform: translate(-50%, -50%);
      }
      // transition: transform 300ms cubic-bezier(0.17, 0.04, 0.03, 0.94);
    }

    &.left-in {
      // transform: translate(-175%, -50%);

      &.live {
        // transform: translate(-50%, -50%);
      }
      // transition: transform 300ms cubic-bezier(0.17, 0.04, 0.03, 0.94);
    }
  }
}

.disconnect-modal {
  position: absolute;
  top: var(--48px);
  right: var(--0px);
  background-color: var(--text-negative);
  box-shadow: 0px var(--10px) var(--40px) var(--black-alpha-15);
  border: 1px solid var(--black-alpha-10);
  border-radius: var(--2px);
  z-index: 100;
  height: fit-content;
  width: fit-content;

  .inner {
    display: flex;
    flex-direction: column;
    gap: var(--16px);
    padding: var(--20px);
    width: var(--262px);

    .add-bar {
      @include SRegular;
      display: flex;
      align-items: center;
      justify-content: center;
      gap: var(--8px);

      img,
      button {
        height: var(--16px);
        width: var(--16px);
      }

      @keyframes buttonClicked {
        0% {
          transform: scale(1);
        }
        50% {
          transform: scale(0.95);
        }
        100% {
          transform: scale(1);
        }
      }

      button:active {
        box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
        animation: buttonClicked 0.2s ease;
      }
    }

    &::-webkit-scrollbar {
      display: none;
    }

    .disconnect {
      display: flex;
      flex-direction: column;
      gap: var(--10px);
    }

    .links {
      display: flex;
      gap: var(--18px);
    }
  }
}

.transfer-success {
  width: var(--480px);
  padding: var(--24px);
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: var(--32px);
  justify-content: space-between;
  border-radius: var(--2px);
  border: 1px solid var(--black-alpha-10);

  .transfer-text {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: var(--12px);
    text-align: center;

    h3 {
      @include XL2Medium;
    }

    h3.ticker {
      text-transform: uppercase;
    }
  }

  a {
    color: var(--primary);
    @include SMedium;
  }
}

.full-modal {
  overflow-y: auto;
  z-index: 97;
  position: fixed;
  top: 0;
  left: 0;
  opacity: 0; /* Start invisible */
  visibility: hidden; /* Start hidden */
  transition: opacity 200ms ease-in, visibility 10ms ease-in,
    transform 300ms cubic-bezier(0.17, 0.04, 0.03, 0.94); /* Add transform to transitions */
  transform: translateX(-100%); /* Start off-screen */
  height: 100vh;
  width: 100vw;
}

.full-modal.is-active {
  display: flex; /* Ensure display is not none to enable transition */
  opacity: 1;
  visibility: visible;
  transform: translateX(0%); /* Slide in */
}

.full-modal-content {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%; /* Ensure it fills the modal */
}

.error-modal {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: var(--32px);
  padding: var(--32px) var(--16px);
  width: var(--560px);
  border-radius: var(--2px);
  box-shadow: 0px 10px 40px 0px rgba(0, 0, 0, 0.1);

  .big-i {
    height: var(--48px);
    width: var(--48px);
  }

  h1 {
    @include XL3Medium;
  }

  a {
    color: var(--primary);
    @include MMedium;
    cursor: pointer;
  }
}

.app-error-modal {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: var(--32px);
  padding: var(--32px) var(--16px);
  width: var(--560px);
  border-radius: var(--2px);
  box-shadow: 0px 10px 40px 0px rgba(0, 0, 0, 0.1);

  .big-i {
    height: var(--48px);
    width: var(--48px);
  }

  h1 {
    @include XL3Medium;
  }
}

.blocked {
  text-align: center;
  h2 {
    @include XL2Medium;
  }

  p {
    @include LMedium;
  }

  a {
    color: var(--primary);
  }
}
