$nav-height: var(--72px);

.nav-wrapper {
  width: 100%;
  position: fixed;
  top: 0;
  z-index: 11;
  background-color: var(--text-negative);

  border-bottom: 1px solid transparent;

  &.divide {
    border-bottom: 1px solid var(--black-alpha-10);
  }

  &.scrolled {
    backdrop-filter: blur(11.5px);
    -webkit-backdrop-filter: blur(11.5px);
    background: rgba(255, 255, 255, 0.1);
  }

  .top-nav {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: $nav-height;
    // background-color: var(--text-negative);
    width: 100%;
    max-width: var(--1488px);
    margin: 0 auto;
    padding: 0 var(--40px);

    .left-logo {
      display: flex;
      align-items: center;
      gap: var(--4px);
    }

    .piece {
      flex: 1;
    }

    .mid-links {
      display: flex;
      align-items: center;
      gap: var(--16px);
      justify-content: center;

      @include mobile-style {
        display: none;
      }
    }

    .buttons {
      display: flex;
      align-items: center;
      position: relative;
      justify-content: flex-end;
      gap: var(--16px);
    }
  }
}

.section {
  padding: 0;
  max-width: var(--1488px);
  display: flex;
  flex-direction: column;
  flex-grow: 1;

  @include mobile-style {
    max-width: 100vw;
  }

  &.no-height {
    min-height: fit-content;
    height: fit-content;
    margin-bottom: 0px;
  }

  // height:
}

.section-1200 {
  padding: 0;
  max-width: var(--1200px);
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  margin-bottom: 32px;
}

.section-wrapper {
  display: flex;
  justify-content: center;
  height: fit-content;
}

.columns {
  margin: 0 !important;
  &:not(:last-child) {
    margin-bottom: 0;
  }

  .column {
    padding-left: 0;
    padding-right: 0;
    padding-top: 0;
    padding-bottom: 0;
  }
}

.global-mr {
  margin-right: var(--16px);
}

.divider-global {
  height: 90%;
  background-color: var(--black-alpha-10);
  width: 1px;
}

.blue-link {
  color: var(--primary);
}

.desk-only {
  display: flex;
  @include mobile-style {
    display: none !important;
  }
  width: 100%;
}

.desk-only-no-width {
  display: flex;
  @include mobile-style {
    display: none !important;
  }
}

.tsp {
  background-color: transparent;
}

.burger-nav {
  display: flex;
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100vw;
  z-index: 9999;
  background-color: var(--text-negative);
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;

  .burg-logo-left {
    position: absolute;
    top: var(--20px);
    left: var(--24px);
  }

  .burg-links {
    display: flex;
    flex-direction: column;
    gap: var(--24px);
    margin-top: var(--164px);
    margin-left: var(--24px);

    a {
      @include XL2Medium;

      &.selected {
        color: var(--primary);
      }
    }
  }

  .bottom-links {
    display: flex;
    flex-direction: column;
    gap: var(--24px);
    padding: var(--24px);

    // height: var(--120px);
    width: 100%;

    color: var(--text-secondary);

    a {
      @include SMedium;
    }
  }
}

.wallet-img {
  background-color: transparent;
  margin-right: var(--8px);
}

.vert-align {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.splash-container {
  position: fixed;
  width: 100%;
  height: calc(100vh - $nav-height);
  // overflow: hidden;
}

.splash-bg {
  position: relative;
  width: 100vw;
  height: calc(100vh - $nav-height);
  overflow: hidden;
  z-index: -1;
  margin-top: 20vh;

  &::before {
    content: '';
    position: absolute;
    bottom: -10%;

    width: 100%;
    height: 100%;
    background: radial-gradient(
      circle at bottom right,
      rgba(0, 255, 224, 0.5) 0%,
      rgba(79, 187, 135, 0.2) 50%,
      transparent 70%
    );
    filter: blur(80px);
    z-index: -1;
  }
}

.splash {
  position: relative;
  z-index: 1;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: transparent;
  max-width: var(--1488px);
  overflow-x: hidden;
  scrollbar-width: none; /* Firefox */
  -ms-overflow-style: none; /* Internet Explorer 10+ */
  padding: var(--0px) var(--40px);

  &::-webkit-scrollbar {
    display: none; /* WebKit */
  }

  .splash-col {
    display: flex;
    flex-direction: column;
    justify-content: center;
    flex: 1;

    &.left {
      gap: var(--40px);
      text-align: left;

      h1 {
        @include XL6Bold;
      }

      h2 {
        @include XL3Medium;
        color: var(--text-secondary);
      }

      button {
        @include XL2Bold;
        padding: var(--8px) var(--28px);
      }
    }

    &.right {
      .inner-right {
        overflow-y: auto;
        scrollbar-width: none; /* Firefox */
        -ms-overflow-style: none; /* Internet Explorer 10+ */
        max-height: 70vh;
        mask-image: linear-gradient(to bottom, transparent, black 10%, black 90%, transparent);
        -webkit-mask-image: linear-gradient(
          to bottom,
          transparent,
          black 10%,
          black 90%,
          transparent
        );
      }

      &::-webkit-scrollbar {
        display: none; /* WebKit */
      }

      a {
        margin-top: var(--16px);
        @include LBold;
        color: var(--primary);
      }
    }
  }
}

.nav-spacer {
  height: $nav-height;
  width: 100%;
}

.error-fallback {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
}
