.info-box {
  padding: var(--8px) 0 var(--8px) var(--12px);
  margin-top: var(--24px);
  border-radius: var(--2px);
  position: relative;
  display: flex;
  align-items: center;

  &.hide {
    display: none;
  }

  &.blue {
    background-color: var(--primary-10);
  }

  &.red {
    background-color: var(--red-muted);
    padding-right: var(--12px);
  }

  &.orange {
    background-color: var(--orange-muted);
    padding-right: var(--12px);
  }

  img {
    margin-right: var(--16px);
  }

  &.full-width {
    width: 100%;
  }

  p {
    @include SRegular;
    color: var(--text-secondary);
    width: 95%;
  }

  a {
    @include SMedium;
  }
}

.close {
  cursor: pointer;
  position: absolute;
  top: 0;
  right: 0;
  padding: var(--20px);
  margin-right: 0 !important;
  z-index: 10;

  &.white {
    background-color: var(--surface-primary);
    border-radius: 9999rem;
    top: calc(var(--12px) * -1);
    right: calc(var(--12px) * -1);
    height: var(--32px);
    width: var(--32px);
    padding: var(--8px);
  }
}

.data-point {
  display: flex;
  flex-direction: column;
  gap: var(--9px);

  &.is-horizontal {
    flex-direction: row-reverse;
    gap: var(--8px);
  }

  &.thin-lined {
    .label {
      cursor: default;
      @include SRegular;
      color: var(--text-secondary);
      text-decoration: underline;
      text-decoration-style: dotted;
      text-underline-offset: var(--5px);
    }
  }

  &.is-right {
    .tip {
      right: 0;
    }
    .value {
      align-self: flex-end;
    }
  }

  .label-wrap {
    display: flex;
    align-items: center;
    gap: var(--6px);
    text-wrap: nowrap;

    .label {
      @include SRegular;
      color: var(--text-secondary);

      &.light {
        @include LRegular;
      }
    }
  }

  .value {
    @include XLMedium;
    color: var(--text-primary);

    &.disabled {
      color: var(--black-alpha-20);
    }

    &.only-val {
      @include LMedium;
    }

    &.light {
      @include XL3Regular;
    }
  }

  .sub-value {
    @include XLBold;
    color: var(--black-alpha-50);
    padding: 0 var(--4px);
    width: fit-content;
    border-radius: var(--4px);

    &.positive {
      color: var(--primary);
      background-color: var(--primary-extra-light);
    }
  }
}

.search-container {
  display: flex;
  align-items: center;
  @include LRegular;

  &.open {
    min-width: var(--320px);
    border: 1px solid var(--primary);
    border-radius: var(--4px);
  }

  @include mobile-style {
    min-width: var(--100px);
  }

  .search-icon-container {
    // padding: var(--4px);
    // border-radius: 9999rem;
    padding: var(--8px);
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    height: var(--48px);
    width: var(--48px);

    &:hover {
      background-color: var(--surface-05);
      border-radius: 9999rem;
    }
  }

  input {
    background-color: transparent;
    padding-left: var(--8px);
    width: 100%;
    @include LMedium;

    &::placeholder {
      /* Chrome, Firefox, Opera, Safari 10.1+ */
      color: var(--surface-50);
      opacity: 1; /* Firefox */
    }

    &:-ms-input-placeholder {
      /* Internet Explorer 10-11 */
      color: var(--surface-50);
    }

    &::-ms-input-placeholder {
      /* Microsoft Edge */
      color: var(--surface-50);
    }
  }
}

.tabs {
  display: flex;
  align-items: center;

  &.full-width {
    width: 100%;

    button {
      width: 100%;
    }

    button:not(:last-of-type) {
      margin-right: var(--2px);
    }
  }

  &.basic {
    button {
      // @include LargeButton;
      height: var(--32px);
      @include LMedium;
      background-color: var(--black-alpha-05);
      padding: var(--6px) var(--12px) var(--7px);
      color: var(--text-secondary);
      white-space: nowrap;

      &:first-child {
        border-top-left-radius: 9999rem;
        border-bottom-left-radius: 9999rem;
      }

      &:last-child {
        border-top-right-radius: 9999rem;
        border-bottom-right-radius: 9999rem;
      }

      &.active {
        color: var(--text-negative);
        background-color: var(--secondary) !important;
      }
    }

    button:not(:last-of-type) {
      margin-right: var(--2px);
    }

    button:hover {
      background-color: var(--black-alpha-10);
    }

    .divider {
      width: var(--1px);
      height: var(--35px);
      background: var(--black-alpha-10);
      border-radius: var(--1px);
      margin-left: var(--24px);
      margin-right: var(--24px);
    }
  }

  &.tab-link {
    height: var(--30px);
    @include XLMedium;
    gap: var(--24px);

    .regular {
      padding-bottom: var(--8px);
      border-bottom: 1.5px solid transparent;
      color: var(--black-alpha-50);
      background-color: transparent;
    }

    .active {
      color: var(--text-secondary);
      border-bottom: 1.5px solid var(--primary);
    }
  }
}

.tip-box {
  // height: var(--18px);
  // width: var(--18px);
  position: relative;
  display: flex;

  &.c-row {
    margin-left: var(--28px);
    visibility: hidden;
    .tip-icon {
      height: var(--16px);
      width: var(--16px);
    }
  }

  .tip-icon {
    height: var(--20px);
    width: var(--20px);
    align-self: center;
  }

  .tip-label {
    cursor: default;
    @include SRegular;
    color: var(--text-secondary) !important;
    text-decoration: underline;
    text-decoration-style: dotted;
    text-underline-offset: var(--4px);
  }
  .tip-value {
    position: absolute;
    z-index: 11;
    visibility: hidden;
    border-radius: var(--2px);
    padding: var(--8px) var(--12px);
    background-color: var(--black-alpha-85);
    @include MRegular;
    width: var(--240px);
    transition: visibility 10ms ease-in;
    transition: transform 200ms cubic-bezier(0.17, 0.04, 0.03, 0.94);

    p {
      color: var(--text-negative) !important;
    }

    &.top-down {
      top: 100%;
    }

    &.left {
      // transform: translateX(100%);
      left: -800%;
    }
  }

  .tip-image {
    &.small {
      height: var(--20px);
      width: var(--20px);
    }
  }

  &:hover {
    .tip-value {
      visibility: visible;
      transform: translateY(1rem);
    }
  }
}

.meta-token {
  display: flex;
  align-items: center;
  gap: var(--8px);

  img {
    height: var(--48px);
    width: var(--48px);
  }

  &.small {
    .meta-text {
      gap: 0;
    }
    .meta-ticker {
      @include LBold;
      color: var(--text-primary);
    }
  }

  .meta-text {
    display: flex;
    flex-direction: column;
    gap: var(--4px);
    @include XLMedium;

    &.layer-token-margin {
      margin-left: var(--70px);
    }

    .meta-pills {
      display: flex;
      align-items: center;
      gap: var(--4px);
      flex-wrap: wrap;
      max-width: 100%;
    }
  }
}

.double-data-point {
  display: flex;
  flex-direction: column;
  gap: var(--8px);
  align-items: flex-end;

  .top,
  .bottom {
    display: flex;
    gap: var(--8px);
    align-items: center;
  }

  .bottom-no-position {
    display: flex;
    flex-direction: column;
    gap: var(--4px);
    align-items: flex-end;
  }

  .top {
    @include XLRegular;

    .label {
      color: var(--text-secondary);
    }

    .value {
      @include XLMedium;
      color: var(--text-primary);
    }
  }

  .bottom,
  .bottom-no-position {
    .label {
      @include XSRegular;
      color: var(--text-secondary);
    }

    .value {
      @include XSMedium;
      color: var(--text-primary);
    }
  }
}

.pill {
  @include XSBold;
  padding: var(--2px) var(--8px);
  background-color: transparent;
  border-radius: var(--4px);
  border: 1px solid var(--surface-20);

  color: var(--pill-grey);

  &.yellow {
    color: var(--pill-yellow);
  }

  &.pink {
    color: var(--pill-pink);
  }

  &.blue {
    color: var(--pill-blue);
  }

  &.grey {
    color: var(--pill-grey);
  }

  &.cyan {
    color: var(--pill-cyan);
  }

  &.purple {
    color: var(--pill-purple);
  }
}

.token-pill {
  display: flex;
  align-items: center;
  gap: var(--8px);
  background-color: var(--surface-08);
  padding: var(--4px) var(--12px) var(--4px) var(--4px);
  border-radius: 9999rem;
  width: fit-content;
  text-wrap: nowrap;

  &.clear {
    background-color: transparent;

    p {
      @include XL22Medium;
    }

    img {
      height: var(--40px);
      width: var(--40px);
    }
  }

  &.no-ticker {
    padding: var(--4px) var(--8px) var(--4px) var(--12px);
    background-color: var(--primary);

    p {
      color: var(--text-negative);
    }
  }

  img {
    height: var(--28px);
    width: var(--28px);
  }

  p {
    @include XLBold;
  }

  .chevron {
    height: var(--16px);
    width: var(--16px);
  }
}

.line-item-list {
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: var(--8px);

  @include mobile-style {
    margin-top: var(--16px);

    .line-item {
      .label {
        text-wrap: nowrap;
      }
    }
  }

  &.tight {
    gap: var(--12px);

    .line-item {
      .label {
        @include SRegular;
      }

      .values {
        .value {
          @include SMedium;
        }
      }
    }
  }
}

.line-item {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;

  .tip-svg {
    height: var(--16px);
    width: var(--16px);
  }

  .label {
    p {
      @include LRegular;
      color: var(--text-secondary);
    }
    margin-right: var(--6px);
  }

  .values {
    display: flex;
    align-items: center;
    gap: var(--8px);
    .value {
      @include LMedium;
      color: var(--text-primary);
    }

    .nextVal {
      @include mobile-style {
        text-align: right;
      }
    }
  }
}

.more-container {
  display: flex;
  justify-content: space-between;
  align-items: center;

  .line {
    width: 100%;
    background-color: var(--black-alpha-10);
    height: 1px;
  }

  .btn {
    &.transparent {
      color: var(--surface-60);
      @include XSBold;
      padding: var(--8px) var(--16px);
    }
  }
}

.a-link {
  @include LMedium;
  background-color: transparent;
  height: fit-content;
  color: var(--text-secondary);

  &.bg-grey-select {
    background-color: transparent;
    @include LMedium;
    padding: var(--4px) var(--16px);

    &.selected {
      background-color: var(--surface-05);
      border-radius: 9999rem;
    }

    &:hover {
      background-color: var(--surface-05);
      border-radius: 9999rem;
      color: var(--primary);
    }
  }

  &.white {
    color: var(--text-negative);
  }

  &.grey {
    color: var(--black-alpha-50);
  }

  &.pri {
    color: var(--primary);
  }

  &.black {
    color: var(--text-primary);
  }
}

.dropdown {
  position: relative;

  &-header {
    padding: var(--4px) var(--8px) var(--4px) var(--12px);
    background-color: transparent;
    border: 1px solid var(--surface-20);
    border-radius: 9999rem;
    cursor: pointer;
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: var(--8px);
    @include MMedium;
    background-color: var(--text-negative);
    height: var(--30px);

    p {
      color: var(--text-primary);
    }

    &:hover {
      border: 1px solid var(--surface-50);
    }
  }

  &-options {
    position: absolute;
    top: 110%;
    left: 0;
    right: 0;
    background-color: var(--text-negative);
    border: 1px solid var(--surface-20);
    border-radius: var(--16px);
    box-shadow: 0px 7px 29px 0px rgba(0, 0, 0, 0.15);
    list-style-type: none;
    padding: var(--8px);
    overflow-y: auto;
    z-index: 1;
    width: var(--200px);
  }

  &-option {
    padding: var(--8px) var(--16px);
    border-radius: var(--8px);
    cursor: pointer;

    span {
      @include MMedium;
      color: var(--text-secondary) !important;
    }

    &:hover {
      background-color: var(--surface-05);
    }

    &.selected {
      display: flex;
      justify-content: space-between;
    }
  }
}

.pending-spinner {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;

  &.simple {
    height: 100%;
    width: 100%;
  }

  .title {
    @include XLMedium;
    margin-top: var(--20px);
  }

  .ps-mid {
    align-self: center;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    @include LMedium;

    .spinner {
      margin-bottom: var(--40px);
      width: 100px;
      height: 100px;
      border-radius: 50%;
      background: conic-gradient(var(--primary), #1c3cf500, var(--primary));
      mask: linear-gradient(#fff 0 0) padding-box,
        radial-gradient(circle closest-side, transparent 100%, #000 0);
      animation: spin 1s cubic-bezier(0.645, 0.045, 0.355, 1) infinite;
    }

    .spinner::before {
      content: '';
      position: absolute;
      top: 5%;
      left: 5%;
      width: 90%;
      height: 90%;
      background: white;
      border-radius: 50%;
    }

    @keyframes spin {
      0% {
        transform: rotate(0deg);
      }
      100% {
        transform: rotate(360deg);
      }
    }
  }
}

.chart-container {
  width: 100%;
  min-height: 20rem;

  &.no-data {
    background-color: var(--surface-05);
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: var(--16px);

    p {
      @include LMedium;
      color: var(--text-secondary);
    }
  }
}

.chart-label-container {
  display: flex;
  flex-direction: column;
  background-color: var(--text-negative);
  border-radius: 4px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  font-family: 'Inter', sans-serif;
  border: 1px solid var(--black-alpha-05);
  padding: var(--16px);

  .label-row {
    display: flex;
    justify-content: space-between;
    // &:not(:first-child) {
    //   margin-top: -16px;
    // }

    .label-value {
      @include MRegular;

      margin-left: var(--8px);
    }

    .label-name {
      @include MMedium;
    }
  }
}

.rewards-pill {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: var(--16px);

  &.full {
    width: 100%;

    .rewards-title {
      @include LMedium;
      color: var(--text-secondary);
    }

    .rewards-value {
      @include XLMedium;
      color: var(--text-primary);
    }
  }

  .rewards-drop {
    display: flex;
    align-items: center;
    gap: var(--8px);
    cursor: pointer;

    p {
      @include LMedium;
      color: var(--text-secondary);
    }
  }

  .rewards-content {
    display: flex;
    align-items: center;
    gap: var(--8px);

    .rewards-info {
      text-align: right;
      .rewards-value {
        @include XLMedium;
        color: var(--text-primary);
      }

      .rewards-all {
        @include MMedium;
        color: var(--text-tertiary);
      }
    }
  }
}

.rewards-drop-content {
  display: flex;
  flex-direction: column;
  gap: var(--8px);
  position: relative;
  top: 0;
  left: 0;
  width: 100%;
  background-color: var(--surface-05);
  padding-top: 3rem;
  padding: 3rem var(--24px) var(--24px) var(--24px);
  margin-top: -3rem;
  z-index: -1;
  border-bottom-right-radius: var(--24px);
  border-bottom-left-radius: var(--24px);
  .rewards-drop-title {
    @include LMedium;
    color: var(--text-primary);
  }
}

.eclipse {
  position: relative;
  background: var(--rewards-gradient);
  color: var(--text-primary);
  overflow: hidden;
  border-radius: 9999rem;

  &::before {
    content: '';
    position: absolute;
    top: 2px;
    left: 2px;
    right: 2px;
    bottom: 2px;
    background-color: var(--white);
    border-radius: calc(9999rem - 2px);
    z-index: 0;
  }

  > * {
    position: relative;
    z-index: 1;
  }

  // &:hover:not(:disabled) {
  //   &::before {
  //     background-color: var(--primary-10);
  //   }
  // }
}

.gradient-text {
  position: relative;
  display: inline-block;
  padding: 2px;

  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: linear-gradient(90deg, var(--primary), var(--primary-light));
    border-radius: var(--8px);
    z-index: 0;
  }

  .inner-content {
    position: relative;
    background-color: var(--text-negative);
    border-radius: var(--6px);
    z-index: 1;
    padding: var(--8px) var(--16px);
    display: block;
  }
}

.token-select-list-container {
  display: flex;
  flex-direction: column;
  gap: var(--20px);
  padding: var(--20px) 0;
  background-color: var(--text-negative);
  padding: var(--16px);
  width: var(--400px);
  border-radius: var(--16px);
  box-shadow: 0px 7px 29px 0px rgba(0, 0, 0, 0.25);
  height: var(--766px);

  .token-select-list-inner {
    display: flex;
    flex-direction: column;
    overflow-y: auto;

    scrollbar-width: none;
    -ms-overflow-style: none;

    &::-webkit-scrollbar {
      display: none;
    }
  }

  .token-select-list-header {
    @include XLMedium;
    color: var(--text-primary);
    text-align: center;
  }

  .token-select-list-label {
    @include MRegular;
    color: var(--text-tertiary);
  }
}

.token-select-row {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  border-radius: var(--4px);
  cursor: pointer;
  padding: var(--8px);
  border-radius: var(--8px);

  .token-select-row-balance {
    @include SMedium;
    color: var(--text-primary);
    text-align: right;
  }

  &:hover {
    background-color: var(--surface-05);
  }
}

.multi-step {
  display: flex;
  flex-direction: column;
  gap: var(--16px);
  width: 100%;
  padding: var(--16px);
  background-color: var(--primary-10);

  .multi-step-info {
    @include SRegular;
    color: var(--text-secondary);
    text-align: center;
  }

  .multi-step-list {
    display: flex;
    flex-direction: column;
    gap: var(--8px);
    width: 100%;

    .step-row {
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 100%;

      .step-row-details {
        display: flex;
        align-items: center;
        gap: var(--12px);

        .step-row-index {
          @include MMedium;
          color: var(--text-tertiary);
        }

        .step-row-label {
          @include MMedium;
          color: var(--text-primary);
        }
      }
    }

    .step-row-status {
      @include MMedium;
      color: var(--text-tertiary);

      &.completed {
        color: var(--success-green);
      }

      &.pending {
        color: var(--pill-yellow);
      }
    }
  }
}

.mandala-background {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: radial-gradient(circle at center, transparent 0%, rgba(79, 187, 135, 0.02) 100%),
    repeating-conic-gradient(
      from 120deg,
      rgba(79, 187, 135, 0.03) 0deg,
      rgba(79, 187, 135, 0.2) 15deg,
      transparent 250deg,
      transparent 1deg
    ),
    repeating-conic-gradient(
      from -60deg,
      rgba(79, 187, 135, 0.05) 0deg,
      rgba(79, 187, 135, 0.15) 20deg,
      transparent 1deg,
      transparent 160deg
    ),
    repeating-conic-gradient(
      from 35deg,
      rgba(79, 187, 135, 0.02) 0deg,
      rgba(79, 187, 135, 0.08) 25deg,
      transparent 1deg,
      transparent 120deg
    );
  background-size: 100% 100%;
  background-position: center;
  pointer-events: none;
  z-index: -1;
  animation: pulse 15s ease-in-out infinite;
}

.gradient-background {
  position: fixed;
  top: 0;
  left: 0;
  margin-top: 20vh;
  width: 100vw;
  height: 80vh;

  background: linear-gradient(to bottom, rgba(255, 255, 255, 1) 0%, rgba(255, 255, 255, 0) 10%),
    radial-gradient(
      circle at bottom right,
      rgba(79, 187, 135, 0.35) 0%,
      rgba(79, 187, 135, 0.05) 40%,
      transparent 100%
    );
  pointer-events: none;
  z-index: -1;
}

@keyframes pulse {
  0% {
    background-position: center;
  }
  50% {
    background-position: 51% 51%;
  }
  100% {
    background-position: center;
  }
}

.disabled {
  cursor: not-allowed !important;
}

.layer-token {
  position: relative;
  display: inline-block;
  height: var(--48px);

  img {
    width: var(--48px);
    height: var(--48px);
    position: absolute;

    &:first-child {
      z-index: 2;
      left: 0;
    }

    &:last-child {
      z-index: 1;
      left: -4px;
      top: 6px;
      height: var(--36px);
      width: var(--36px);
      transform: translateX(90%);
      opacity: 0.9;
    }
  }
}
