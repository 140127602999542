.tx-form {
  padding: var(--24px) var(--16px);
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: var(--16px);
  width: var(--464px);
  box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.3);
  border-radius: var(--24px);
  background-color: var(--text-negative);

  .claim-label {
    @include LRegular;
    color: var(--text-secondary);
    text-align: left;
    width: 100%;
  }

  &.quick-deposit {
    width: 100%;

    .recommended {
      width: 100%;
      text-align: left;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      gap: var(--8px);

      .recommended-title {
        @include MRegular;
        color: var(--text-secondary);
      }
    }
  }

  h3 {
    @include XL2Medium;
    color: var(--text-primary);
  }

  .tx-form-input-container {
    display: flex;
    flex-direction: column;
    gap: var(--48px);
    padding: var(--16px);
    border-radius: var(--24px);
    border: var(--border);
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.05);
    width: 100%;
    background-color: var(--surface-05);
    .tx-input {
      display: flex;
      align-items: center;
      justify-content: space-between;

      .tx-input-stack {
        display: flex;
        align-items: center;
        gap: var(--4px);
        flex-direction: column;
        align-items: flex-start;
        input {
          @include Jumbo;
          color: var(--text-primary);
          border: none;
          outline: none;
          width: 100%;
          background-color: transparent;

          &::placeholder {
            color: var(--text-tertiary) !important;
          }
        }

        .tx-input-detail {
          @include LRegular;
          color: var(--text-secondary);
        }
      }
    }

    .tx-form-input-actions {
      display: flex;
      align-items: center;
      justify-content: space-between;

      .action-btns {
        display: flex;
        align-items: center;
        gap: var(--4px);
      }

      .wallet-balance {
        display: flex;
        align-items: center;
        gap: var(--4px);

        .wallet-balance-label {
          @include MRegular;
          color: var(--text-secondary);
        }

        .wallet-balance-value {
          @include LRegular;
          color: var(--text-primary);
        }
      }
    }
  }

  .tx-input-review {
    display: flex;
    flex-direction: column;
    gap: var(--4px);
    width: 100%;

    .label {
      @include LRegular;
      color: var(--text-secondary);
    }

    .review-bal {
      @include Jumbo;
      color: var(--text-secondary);
    }

    .review-val {
      @include LRegular;
      color: var(--text-secondary);
    }
  }

  .tx-form-buttons {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    gap: var(--8px);

    .type-grey {
      width: 35%;
    }
  }
}

.my-position {
  display: flex;
  align-items: center;
  justify-content: space-between;

  padding: var(--24px) var(--16px) var(--32px) var(--16px);
  background-color: var(--surface-05);
  width: 100%;

  border-top-right-radius: var(--24px);
  border-top-left-radius: var(--24px);

  margin-bottom: calc(var(--32px) * -1);

  .my-position-title {
    @include XLRegular;
    color: var(--text-secondary);
  }

  .my-position-balance {
    display: flex;
    align-items: center;
    gap: var(--8px);

    .dark {
      @include XLMedium;
      color: var(--text-primary);
    }

    .light {
      @include XLMedium;
      color: var(--text-tertiary);
    }
  }
}

.lp-stake {
  display: flex;
  flex-direction: column;
  gap: var(--16px);
  padding: var(--12px);
  background-color: var(--surface-helper);
  border-radius: var(--4px);
  width: 100%;

  .lp-stake-description {
    @include MRegular;
    color: var(--text-secondary);
  }
}
