.rewards-page {
  display: flex;
  align-items: center;
  justify-content: center;
  height: calc(100vh - $nav-height);

  .rewards-inner {
    width: 100%;
    max-width: var(--464px);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: var(--16px);
  }
}
